import Vue from "vue";
import Vuex from "vuex";

// Store Module
import commonModule from "@/store/common";
import modalModule from "@/store/modal";
import utilityModule from "@/store/utility";
import topInitModule from "@/store/top/top-init";
import topCompanyModule from "@/store/top/top-company";
import hallAdminModule from "@/store/hall-admin/main";
import jobModule from "@/store/job/main";

import actions from "@/store/top/actions";
import getters from "@/store/top/getters";
import mutations from "@/store/top/mutations";

Vue.use(Vuex);

import API_CODE from "@/assets/common/ApiCode";

export default new Vuex.Store({
  modules: {
    common: commonModule,
    modal: modalModule,
    utility: utilityModule,
    topInit: topInitModule,
    topCompany: topCompanyModule,
    hallAdmin: hallAdminModule,
    job: jobModule,
  },
  state: {
    loading: {
      fair: true,
      fairList: true,
      plan: true,
    },
    news: {
      list: [],
      master: [],
    },
    link: {
      cautions: [],
    },
    account: {
      list: [],
      log: {},
      count: 0,
      log_status: {},
    },
    benefit: {
      list: [],
      display: 2,
      count: {
        register: 0,
        expiredNum: {
          soon: 0,
          over: 0,
        },
        group: {},
      },
    },
    hallBenefit: {
      list: [],
      display: 2,
      count: {
        register: 0,
        expiredNum: {
          soon: 0,
          over: 0,
        },
        group: {},
      },
    },
    image: {
      list: [],
      display: 4,
      count: {
        register: 0,
        expiredNum: {
          soon: 0,
          over: 0,
        },
        max: 0,
      },
    },
    fair: {
      list: [],
      monthly_count: [],
      calendar: {},
      schedule: {},
    },
    media: {},
    plan: {
      list: [],
      count: {
        register: 0,
        expiredNum: {
          soon: 0,
          over: 0,
        },
      },
    },
    login: {
      [API_CODE.media.zexy]: {
        action: "https://cszebra.zexy.net/id/login/",
        param: {
          id: "userId",
          password: "password",
          submit: "doLogin",
        },
        id: "",
        password: "",
        submit: "ログイン",
      },
      [API_CODE.media.mynavi]: {
        action: "https://wedding.mynavi.jp/client/login/",
        param: {
          id: "loginUserName",
          password: "loginUserPassword",
          submit: "login",
        },
        id: "",
        password: "",
        submit: "ログイン",
      },
      [API_CODE.media.ksm]: {
        action: "https://www.niwaka-ksm.com/edit/login_check.php",
        param: {
          id: "id",
          password: "pw",
          submit: "send",
        },
        id: "",
        password: "",
        submit: "ログイン",
      },
      [API_CODE.media.hanayume]: {
        action: "https://hana-yume.net/admin/auth/login",
        param: {
          form: "login",
          id: "authLogin[login_id]",
          password: "authLogin[password]",
          submit: "",
        },
        id: "",
        password: "",
        submit: "",
      },
      [API_CODE.media.mw]: {
        url: "https://b.mwed.jp/sign_in",
        id: "",
        password: "",
        submit: "",
      },
      [API_CODE.media.wp]: {
        url: "https://wplanet.weddingpark.net",
        id: "",
        password: "",
        submit: "",
      },
      [API_CODE.media.homepage]: {
        url: "",
        id: "",
        password: "",
        submit: "",
      },
    },
    isAuthorizedUnit: {
      info: {
        permissionName: "news",
        view: false,
        edit: false,
      },
      media: {
        permissionName: "mediaAccounts",
        view: false,
        edit: false,
      },
      fair: {
        permissionName: "fairs",
        view: false,
        edit: false,
      },
      image: {
        permissionName: "images",
        view: false,
        edit: false,
      },
      benefit: {
        permissionName: "benefits",
        view: false,
        edit: false,
      },
      hallBenefit: {
        permissionName: "hallBenefits",
        view: false,
        edit: false,
      },
      login: {
        permissionName: "mediaDirect",
        view: false,
      },
      setting: {
        permissionName: "settings",
        view: false,
        edit: false,
      },
      plan: {
        permissionName: "plans",
        view: false,
        edit: false,
      },
    },
    roleCode: API_CODE.role,
    isLogModal: false,
  },
  getters,
  mutations,
  actions,
});
